import type { Config } from "tailwindcss";
import containerQueriesPlugin from "@tailwindcss/container-queries";

export default {
  variants: {
    extend: {
      opacity: ["hover"],
    },
  },
  theme: {
    container: {
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      fontFamily: {
        Aeonik: ["Aeonik", "sans-serif"],
        KelsiFill: ["Kelsi-fill", "sans-serif"],
        Kelsi: ["Kelsi", "sans-serif"],
        Kontesa: ["Kontesa", "sans-serif"],
        SubwayTicker: ["SubwayTicker", "sans-serif"],
        Agrandir: ["Agrandir", "sans-serif"],
      },
      fontSize: {
        h1: [
          "145px",
          {
            lineHeight: "0.78em",
            letterSpacing: "-3.35px",
          },
        ],
        h2: [
          "84px",
          {
            lineHeight: "0.8em",
            letterSpacing: "-1.12px",
          },
        ],
        h3: [
          "48px",
          {
            lineHeight: "0.8em",
            letterSpacing: "-0.48px",
          },
        ],
        h4: [
          "32px",
          {
            lineHeight: "0.9em",
            letterSpacing: "-0.64px",
          },
        ],
        h5: [
          "21px",
          {
            lineHeight: "0.9em",
            letterSpacing: "-0.42px",
          },
        ],
        p1: [
          "15px",
          {
            lineHeight: "1.2em",
            letterSpacing: "-0.45px",
          },
        ],
        p2: [
          "12px",
          {
            lineHeight: "1.2em",
            letterSpacing: "-0.12px",
          },
        ],
        "h1-mobile": [
          "52px",
          {
            lineHeight: "0.8em",
            letterSpacing: "-1.56px",
          },
        ],
        "h2-mobile": [
          "36px",
          {
            lineHeight: "0.8em",
            letterSpacing: "-0.36px",
          },
        ],
        "h3-mobile": [
          "28px",
          {
            lineHeight: "0.9em",
            letterSpacing: "-0.56px",
          },
        ],
        "h4-mobile": [
          "21px",
          {
            lineHeight: "0.9em",
            letterSpacing: "-0.42px",
          },
        ],
        "h5-mobile": [
          "15px",
          {
            lineHeight: "0.9em",
            letterSpacing: "-0.3px",
          },
        ],
        "p1-mobile": [
          "15px",
          {
            lineHeight: "1.2em",
            letterSpacing: "-0.45px",
          },
        ],
        "p2-mobile": [
          "12px",
          {
            lineHeight: "1.2em",
            letterSpacing: "-0.12px",
          },
        ],
      },
      colors: {
        orange: "rgb(var(--color-orange) / <alpha-value>)",
        "orange-medium": "rgb(var(--color-orange-medium) / <alpha-value>)",
        "orange-low": "rgb(var(--color-orange-low) / <alpha-value>)",
        pink: "rgb(var(--color-pink) / <alpha-value>)",
        "pink-medium": "rgb(var(--color-pink-medium) / <alpha-value>)",
        "pink-low": "rgb(var(--color-pink-low) / <alpha-value>)",
        yellow: "rgb(var(--color-yellow) / <alpha-value>)",
        "yellow-medium": "rgb(var(--color-yellow-medium) / <alpha-value>)",
        "yellow-low": "rgb(var(--color-yellow-low) / <alpha-value>)",
        purple: "rgb(var(--color-purple) / <alpha-value>)",
        "purple-medium": "rgb(var(--color-purple-medium) / <alpha-value>)",
        "purple-low": "rgb(var(--color-purple-low) / <alpha-value>)",
        ocean: "rgb(var(--color-ocean) / <alpha-value>)",
        "ocean-medium": "rgb(var(--color-ocean-medium) / <alpha-value>)",
        "ocean-low": "rgb(var(--color-ocean-low) / <alpha-value>)",
        nature: "rgb(var(--color-nature) / <alpha-value>)",
        "nature-medium": "rgb(var(--color-nature-medium) / <alpha-value>)",
        "nature-low": "rgb(var(--color-nature-low) / <alpha-value>)",
        success: "rgb(var(--color-success) / <alpha-value>)",
        "success-medium": "rgb(var(--color-success-medium) / <alpha-value>)",
        "success-low": "rgb(var(--color-success-low) / <alpha-value>)",
        navy: "rgb(var(--color-navy) / <alpha-value>)",
        "navy-medium": "rgb(var(--color-navy-medium) / <alpha-value>)",
        "navy-low": "rgb(var(--color-navy-low) / <alpha-value>)",
        error: "rgb(var(--color-error) / <alpha-value>)",
        "main-bg": "rgb(var(--color-main-bg) / <alpha-value>)",
        clean: "rgb(var(--color-clean) / <alpha-value>)",
        inactive: "rgb(var(--color-text-inactive) / <alpha-value>)",
        "inactive-bg": "rgb(var(--color-inactive-bg) / <alpha-value>)",
        "green-lawn": "#96F14F",
        "orange-flame": "#E7622C",
        "blue-deep": "#2824ff",
        "blue-pale-azure": "#7FD9FF",
        "yellow-aureolin": "#FFF629",
        "blue-electric": "#77E9FF",
        aquamarine: "#A2FFD2",
      },
      backgroundImage: {
        "ocean-pink-ocean":
          "linear-gradient(0deg, #78E9FF 0%, #FEE6EE 40%, #FEE6EE 75%, #78E9FF 100%)",
        loader: "linear-gradient(178deg, #E7DBFF -30.02%, #FFF 98.66%)",
        "green-white-ocean":
          "linear-gradient(180deg, rgb(var(--color-nature)) 0%, #FFFFFF 10%, #FFFFFF 85%, rgb(var(--color-ocean)) 100%)",
      },
      boxShadow: {
        base: "2px 2px 0px 5px rgb(var(--color-navy))",
        md: "1px 1px 0px 3px rgb(var(--color-navy))",
        sm: "0.5px 0.5px 0px 2px rgb(var(--color-navy))",
        xs: "0.5px 0.5px 0px 1px rgb(var(--color-navy))",
      },
      keyframes: {
        "overlay-show": {
          from: { opacity: "0" },
          to: { opacity: "1" },
        },
        "content-show": {
          from: {
            opacity: "0",
            transform: "translate(0%, -5%) scale(0.96)",
          },
          to: { opacity: "1", transform: "translate(0%, 0%) scale(1)" },
        },
      },
      animation: {
        "overlay-show": "overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1)",
        "content-show": "content-show 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      },
    },
  },
  plugins: [containerQueriesPlugin],
  content: [
    `./components/**/*.{vue,js,ts}`,
    `./layouts/**/*.vue`,
    `./pages/**/*.vue`,
  ],
  safelist: [
    {
      pattern: /bg-+/,
    },
  ],
} as const satisfies Config;
